<template>
  <b-button class="test-button" @click="renderDoc">Word Export</b-button>
</template>

<script>
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import ImageModule from "docxtemplater-image-module-free";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function replaceErrors(_key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (error, key) {
      error[key] = value[key];
      return error;
    }, {});
  }
  return value;
}

const getImage = (tagValue) => {
  return new Promise(function (resolve, reject) {
    PizZipUtils.getBinaryContent(tagValue, (error, content) => {
      if (error) {
        return reject(error);
      }
      return resolve(content);
    });
  });
};

export default {
  props: ["templateName", "templateData"],
  methods: {
    export(doc) {
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      // Output the document using Data-URI
      saveAs(out, this.templateName);
    },
    renderDoc() {
      loadFile(`/WordTemplates/${this.templateName}.docx`, (error, content) => {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const options = {
          getImage,
          getSize: () => {
            return [400, 250];
          },
        };
        const imageModule = new ImageModule(options);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [imageModule],
        });
        try {
          // Render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          if (!this.templateData.blindad_photo_file) {
            doc.render(this.templateData);
            this.export(doc);
          } else
            doc.renderAsync(this.templateData).then(() => this.export(doc));
        } catch (__error) {
          // The error thrown here contains additional information when logged with JSON.stringify
          // It contains a properties object containing all suberrors.
          console.error(JSON.stringify({ error: __error }, replaceErrors));
          if (
            __error.properties &&
            __error.properties.errors instanceof Array
          ) {
            const errorMessages = __error.properties.errors
              .map(function (_error) {
                return _error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this : 'The tag beginning with "foobar" is unopened'
          }
          throw __error;
        }
      });
    },
  },
};
</script>
